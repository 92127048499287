import React from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import Icon from "@base/components/Global/Icon";
import SpinnerSmall from "@base/components/Global/SpinnerSmall";
import { getBlobStorage } from "@theme";

const useStyles = createUseStyles(({ spacing, color, font, borderRadius }) => ({
  container: {
    width: "fit-content",
    position: "relative",
    paddingTop: 5,
  },
  select: {
    appearance: "none",
    "-moz-appearance": "none",
    "-webkit-appearance": "none",
    boxShadow: ["none", "!important"],
    outline: "none",
    border: `1px solid ${color.fieldBorder}`,
    width: "100%",
    color: color.fieldText,
    fontSize: font.size.s,
    backgroundColor: color.fieldBg,

    height: 48,
    borderRadius: borderRadius.input,

    paddingRight: spacing.x5l,
    paddingLeft: spacing.xl,
  },
  label: {
    position: "absolute",
    top: -5,
    left: 18,
    background: color.fieldBg,
    color: color.fieldLabelText,
    padding: [0, 5],
  },

  suffixIconContainer: {
    position: "absolute",
    top: 17,
    right: 12,
    pointerEvents: "none",
  },
}));

const SelectNative: React.FC<{
  options: { label: string; value: string }[];
  value: string | undefined;
  onSelect: (value: string) => void;
  label?: string;
  width?: string;
  allowUndefinedValue?: boolean;
  suffixIconUrl?: string;
  isLoading?: boolean;
}> = ({
  value,
  options,
  onSelect,
  label,
  width,
  allowUndefinedValue = false,
  suffixIconUrl,
  isLoading = false,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { caretDownThinIcon } = getBlobStorage();

  const renderSuffixIcon = () => {
    if (isLoading) {
      return <SpinnerSmall className={classes.suffixIconContainer} />;
    }

    if (suffixIconUrl) {
      return (
        <Icon
          className={classes.suffixIconContainer}
          url={suffixIconUrl}
          height={24}
          width={24}
        />
      );
    }

    return (
      <Icon
        className={classes.suffixIconContainer}
        url={caretDownThinIcon}
        height={24}
        width={24}
      />
    );
  };

  return (
    <div className={classes.container} style={{ width }}>
      {label ? <span className={classes.label}>{label}</span> : null}
      <select
        tabIndex={0}
        className={classes.select}
        value={value}
        onChange={(e) => {
          onSelect(e.target.value);
        }}
      >
        {allowUndefinedValue && (
          <option value={undefined}>
            {t("form_field_placeholder_choose_time_slot")}...
          </option>
        )}

        {!allowUndefinedValue && !isLoading && options.length === 0 && (
          <option value={undefined}>
            {t("form_field_no_times_available_choice")}
          </option>
        )}

        {options.map((o) => (
          <option value={o.value} key={o.value.toString()}>
            {o.label}
          </option>
        ))}
      </select>
      {renderSuffixIcon()}
    </div>
  );
};

export default SelectNative;

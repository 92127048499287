import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { IDiningOptionEnum } from "@api/interfaces/configurationLayouts";
import { isPresent } from "@apl-digital/utils";
import { useAppDispatch, useAppSelector } from "@store";
import { showDeliveryMethodModal } from "@store/layout";
import { selectIsDiningOptionAvailable } from "@store/salesPoint";
import {
  selectShoppingCart,
  selectShoppingCartDingingOption,
} from "@store/shoppingCart";
import { selectTheatreResource } from "@store/theatreResource";
import { isStatus } from "@store/utils";
import { getBlobStorage, getBrandTheme } from "@theme";

import Button, { BUTTON_MODS } from "../Global/Button";
import Icon from "../Global/Icon";
import { TheatreResourceModal } from "../Modals/TheatreResourceModal/TheatreResourceModal";

const DiningOptionButtonContent = () => {
  const { color } = getBrandTheme();
  const { qrIcon, diningOptionIconDineIn, diningOptionIconTakeaway } =
    getBlobStorage();
  const { t } = useTranslation();

  const tableInfo = useAppSelector(selectTheatreResource);
  const diningOptionID = useAppSelector(selectShoppingCartDingingOption);

  if (
    isStatus(tableInfo, ["succeeded", "stale"]) &&
    diningOptionID !== IDiningOptionEnum.TAKE_OUT
  ) {
    return (
      <>
        <Icon
          height={24}
          width={24}
          url={qrIcon}
          stroke={color.navbarDiningMethodButtonText}
        />
        {t("table_code", {
          tableCode:
            tableInfo.state.Name.length >= 9
              ? tableInfo.state.Name.slice(0, 6) + "..."
              : tableInfo.state.Name,
        })}
      </>
    );
  }

  if (diningOptionID === IDiningOptionEnum.DINE_IN) {
    return (
      <>
        <Icon
          height={24}
          width={24}
          url={diningOptionIconDineIn}
          stroke={color.navbarDiningMethodButtonText}
        />
        {t("dining_options_title_dine_in")}
      </>
    );
  }

  if (diningOptionID === IDiningOptionEnum.TAKE_OUT) {
    return (
      <>
        <Icon
          height={24}
          width={24}
          url={diningOptionIconTakeaway}
          stroke={color.navbarDiningMethodButtonText}
        />
        {t("dining_options_title_take_out")}
      </>
    );
  }

  return null;
};

const DiningOptionButton = () => {
  const [shouldShowQrModal, setShouldShowQRModal] = useState<boolean>(false);
  const shoppingCart = useAppSelector(selectShoppingCart);
  const isTakeOutEnabled = useAppSelector((state) =>
    selectIsDiningOptionAvailable(state, IDiningOptionEnum.TAKE_OUT),
  );
  const dispatch = useAppDispatch();

  const handleTableNumberClick = () => {
    if (isTakeOutEnabled) {
      dispatch(showDeliveryMethodModal());
    } else {
      setShouldShowQRModal(true);
    }
  };

  if (!isPresent(shoppingCart?.state?.Defaults?.DiningOptionID)) {
    return null;
  }

  return (
    <>
      <TheatreResourceModal
        isOpen={shouldShowQrModal}
        onClose={() => setShouldShowQRModal(false)}
      />
      <Button
        action={handleTableNumberClick}
        id="dining-option-btn"
        mode={BUTTON_MODS.NAVBAR}
      >
        <DiningOptionButtonContent />
      </Button>
    </>
  );
};

export default DiningOptionButton;

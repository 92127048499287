import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import Button from "@base/components/Global/Button";
import Icon from "@base/components/Global/Icon";
import { ImageCarousel } from "@base/components/Global/ImageCarousel";
import { RichListTranslation } from "@base/components/Global/RichTextTranslation";
import { useRichTextTranslation } from "@base/components/Global/RichTextTranslation/useRichTextTranslation";
import { SimpleLink } from "@base/components/Global/SimpleLink";
import useSalesPointTranslationKey from "@base/hooks/useSalesPointTranslationKey";
import { useWhiteLabelImage } from "@base/hooks/useWhiteLabelImage";
import { getImageFromAPI } from "@base/utils/imageHelper";
import config from "@constants/config";
import { useAppSelector } from "@store";
import { selectAssetCategoryUrls } from "@store/salesPoint";
import { getBlobStorage } from "@theme";

const useStyles = createUseStyles(
  ({ spacing, font, color, mediaQuery, borderRadius }) => ({
    container: {
      display: "flex",
      minHeight: 0,
      padding: spacing.x4l,
      background: color.modalBg,
      gap: spacing.x4l,
      [mediaQuery.md]: {
        width: 736,
        maxHeight: 520,
      },
      [mediaQuery.lg]: {
        width: 1000,
        maxHeight: 706,
      },
    },
    left: {
      gap: spacing.xxl,
      display: "flex",
      flexDirection: "column",
      [mediaQuery.md]: {
        minWidth: 321,
      },
      [mediaQuery.lg]: {
        minWidth: 585,
      },
    },
    image: {
      borderRadius: borderRadius.xl,
      height: 402,
      overflow: "hidden",
    },
    map: {
      height: 227,
      width: "100%",
      borderRadius: borderRadius.xl,
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
    },
    iconWrapper: {
      alignSelf: "flex-start",
      marginRight: spacing.s,
    },
    header: {
      marginTop: 40,
      fontWeight: font.weight.s,
      fontSize: font.size.x3l,
      lineHeight: font.lineHeight.xl,
      color: color.locationInfoHeader,
    },
    actionContainer: {
      display: "flex",
      flexGrow: 1,
      justifyContent: "end",
      alignItems: "end",
    },
    contact: {
      display: "flex",
      flexDirection: "column",
      gap: spacing.x4l,
      flexGrow: 1,
    },
    location: {
      fontWeight: font.weight.m,
      lineHeight: font.lineHeight.m,
      display: "flex",
      color: color.locationText,
    },
    description: {
      fontWeight: font.weight.m,
      lineHeight: font.lineHeight.m,
      fontSize: font.size.s,
      display: "flex",
      color: color.locationDescription,
      overflowY: "auto",
      justifyContent: "space-between",
      flexDirection: "column",
    },
  }),
);

const LocationModalContent: React.FC = () => {
  const classes = useStyles();
  const { locationIcon, locationMap } = getBlobStorage();
  const getWhiteLabeledImage = useWhiteLabelImage();
  const { resolveKey } = useSalesPointTranslationKey();
  const { t, i18n } = useTranslation("salespoint");
  const transProps = useRichTextTranslation();

  const mapImage = getWhiteLabeledImage(locationMap);
  const images = useAppSelector((state) =>
    selectAssetCategoryUrls(
      state,
      "location_page_business_image",
      i18n.language,
    ),
  );

  const shouldShowExtraLink =
    i18n.exists(resolveKey("link.label"), { ns: "salespoint" }) &&
    i18n.exists(resolveKey("link.url"), { ns: "salespoint" });

  const shouldShowExtraButton =
    i18n.exists(resolveKey("cta.label"), { ns: "salespoint" }) &&
    i18n.exists(resolveKey("cta.url"), { ns: "salespoint" });

  return (
    <div className={classes.container}>
      <div className={classes.left}>
        <div className={classes.image}>
          <ImageCarousel
            styleVariant="rounded"
            mode={config.appConfig.carousel.locationPageBusinessImage.mode}
            autoplayInterval={
              config.appConfig.carousel.locationPageBusinessImage
                .autoplayInterval
            }
            images={images.map((image) => ({
              img: getImageFromAPI(image, 800, 490),
              placeholderImg: getImageFromAPI(image, 80, 49),
            }))}
            hasDots
          />
        </div>
        <a
          className={classes.map}
          style={{
            backgroundImage: `url(${getImageFromAPI(
              mapImage || "",
              1001,
              554,
            )})`,
          }}
          href={t(resolveKey("mapUrl"))}
          target="_blank"
        />
      </div>
      <div className={classes.contact}>
        <span className={classes.header}>{t(resolveKey("name"))}</span>
        <div className={classes.location}>
          <div className={classes.iconWrapper}>
            <Icon url={locationIcon} height={24} width={24} />
          </div>
          <div>
            <RichListTranslation
              getTranslation={() =>
                t(resolveKey("addressLines", { returnObjects: true }), {
                  returnObjects: true,
                })
              }
            />
            <RichListTranslation
              getTranslation={() =>
                t(resolveKey("contacts", { returnObjects: true }), {
                  returnObjects: true,
                })
              }
            />
          </div>
        </div>
        <div className={classes.description}>
          <Trans
            {...transProps}
            i18nKey={resolveKey("description")}
            ns="salespoint"
          />
          {shouldShowExtraLink && (
            <SimpleLink href={t(resolveKey("link.url"))} openInNewTab>
              {t(resolveKey("link.label"))}
            </SimpleLink>
          )}
        </div>
        {shouldShowExtraButton && (
          <div className={classes.actionContainer}>
            <Button
              action={() => {
                window.open(t(resolveKey("cta.url")), "_blank");
              }}
            >
              {t(resolveKey("cta.label"))}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default LocationModalContent;

import React from "react";
import { createUseStyles } from "react-jss";
import { useAuth } from "react-oidc-context";
import SignedInBlock from "@base/components/LoyaltyClub/SignedInBlock";
import config from "@constants/config";

import { UserLogin } from "./UserLogin";

type BurgerMenuUserProps = {
  onClick?: () => void;
};

const useStyles = createUseStyles(({ spacing }) => ({
  signedInBlockContainer: {
    padding: [spacing.l, spacing.s],
  },
}));

export const BurgerMenuUser: React.FC<BurgerMenuUserProps> = ({ onClick }) => {
  const { isAuthenticated } = useAuth();
  const classes = useStyles();

  if (!config.featureFlags.isLoginEnabled) {
    return null;
  }

  if (isAuthenticated) {
    return (
      <div className={classes.signedInBlockContainer}>
        <SignedInBlock variant="ghost" />
      </div>
    );
  }

  return <UserLogin onClick={onClick} />;
};

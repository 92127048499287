import React from "react";
import { useCollapse } from "react-collapsed";
import { createUseStyles } from "react-jss";
import { useNavigate } from "react-router-dom";
import Icon from "@base/components/Global/Icon";
import { NavigationPath } from "@constants/navigation";
import { getBlobStorage, getBrandTheme } from "@theme";
import classNames from "classnames";

const useStyles = createUseStyles(({ spacing, font, color }) => ({
  menuItemContainer: {
    padding: [spacing.xl, spacing.x4l],
    display: "flex",
    flexDirection: "column",
    boxShadow: color.burgerMenuBoxShadow,
  },
  menuItem: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    cursor: "pointer",
    justifyContent: "space-between",
  },
  disabled: {
    cursor: "auto",
  },
  menuItemLabel: {
    display: "flex",
    alignItems: "center",
    fontSize: font.size.l,
    fontWeight: font.weight.s,
    color: color.burgerMenuItemLabel,
  },
  marginRight: {
    marginRight: spacing.s,
  },
  menuItemIcon: {
    display: "flex",
    flexDirection: "row",
    marginLeft: "auto",
    gap: spacing.l,
  },
  menuItemDropIcon: {
    transition: "transform 300ms",
    transform: "rotate(0deg)",
    color: color.burgerMenuDropdownIcon,
  },
  rotate: {
    transform: "rotate(180deg)",
  },
  menuSubmenu: {
    paddingTop: spacing.xxl,
    display: "flex",
    flexDirection: "column",
    gap: spacing.xl,
  },
}));

type MenuItemProps = {
  extraInfo?: React.ReactNode;
  label: string;
  path?: string;
  showSubMenu?: boolean;
  children?: React.ReactNode;
  icon?: string;
  interactiveElementId?: string;
  onClick?: () => void;
  newWindow?: boolean;
};

export const MenuItem: React.FC<MenuItemProps> = ({
  extraInfo,
  label,
  path,
  showSubMenu = false,
  children,
  icon,
  interactiveElementId,
  onClick,
  newWindow = false,
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { getCollapseProps, getToggleProps, isExpanded, setExpanded } =
    useCollapse({ defaultExpanded: showSubMenu });

  const { dropMenuIcon, exitPageIcon } = getBlobStorage();
  const { color } = getBrandTheme();

  const hasChildren = React.Children.toArray(children).length > 0;
  const isExpandDisabled = !hasChildren && !path;

  const handleClick = () => {
    if (hasChildren) setExpanded((prev) => !prev);
    else if (path && newWindow) window.open(path, "_blank");
    else if (path) {
      if (Object.values(NavigationPath).includes(path)) {
        navigate(path);
      } else {
        window.open(path);
      }
    }

    onClick?.();
  };

  return (
    <div className={classes.menuItemContainer}>
      <div
        {...getToggleProps({
          disabled: isExpandDisabled,
          onClick: handleClick,
          id: interactiveElementId,
        })}
        className={classNames([
          classes.menuItem,
          isExpandDisabled && classes.disabled,
        ])}
      >
        <div className={classes.menuItemLabel}>
          {icon && (
            <Icon
              className={classes.marginRight}
              url={icon}
              height={24}
              width={24}
            />
          )}
          {label}
        </div>

        {path && newWindow && (
          <Icon
            url={exitPageIcon}
            className={classes.menuItemIcon}
            height={24}
            width={24}
          />
        )}

        {children && (
          <div className={classes.menuItemIcon}>
            {extraInfo}

            <Icon
              url={dropMenuIcon}
              className={classNames([
                classes.menuItemDropIcon,
                isExpanded && classes.rotate,
              ])}
              stroke={
                isExpandDisabled
                  ? color.burgerMenuDropdownIconDisabled
                  : color.burgerMenuIcons
              }
              height={24}
              width={24}
            />
          </div>
        )}
      </div>

      {children && (
        <div {...getCollapseProps()}>
          <div className={classes.menuSubmenu}>{children}</div>
        </div>
      )}
    </div>
  );
};

import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { Retry } from "@base/components/Global/Retry";
import { RetryLoader } from "@base/components/Global/RetryLoader";
import { useAppDispatch } from "@store";
import {
  OrderDataWithGroupingStatusMachine,
  resetOrderStatus,
} from "@store/orders";
import { isStatus } from "@store/utils";
import { getBrandTheme } from "@theme";
import { motion } from "framer-motion";

import { OrderCardContentColumn } from "./OrderCardContentColumn";
import { OrderCardNotFound } from "./OrderCardNotFound";

const useStyles = createUseStyles(({ spacing, color, borderRadius }) => ({
  container: {
    width: "90vw",
    borderRadius: borderRadius.xl,
    padding: spacing.x4l,
    backgroundColor: color.orderStatusCardInactiveBackgroundColor,
  },
  errorContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: spacing.l,
  },
}));

type OrderCardMobileProps = {
  order: OrderDataWithGroupingStatusMachine;
  isSelected?: boolean;
};

export const OrderCardMobile: React.FC<OrderCardMobileProps> = ({
  order,
  isSelected = false,
}) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { t } = useTranslation("orderStatusPage");

  const ref = React.useRef<HTMLDivElement>(null);

  const { color } = getBrandTheme();

  useEffect(() => {
    if (!isSelected) return;

    const timeout = setTimeout(() => {
      ref.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }, 250);

    return () => clearTimeout(timeout);
  }, [isSelected]);

  const isLoading = isStatus(order, ["idle", "pending"]);
  const hasError = isStatus(order, ["failed", "failedPending"]);
  const hasData = isStatus(order, [
    "stale",
    "stalePending",
    "succeeded",
    "orderMoved",
  ]);
  const isNotFound = isStatus(order, ["notFound"]);

  let currentColor = color.orderStatusCardInactiveBackgroundColor;

  if (hasData) {
    if (isStatus(order, "orderMoved")) {
      currentColor = color.orderStatusCardInactiveBackgroundColor;
    } else if (!order.state.isComplete) {
      if (order.state.isPaid) {
        currentColor = color.orderStatusCardPaidBackgroundColor;
      } else {
        currentColor = color.orderStatusCardPendingBackgroundColor;
      }
    }
  }

  return (
    <motion.div
      ref={ref}
      initial={{
        backgroundColor: isSelected
          ? color.orderStatusCardBackgroundHighlight
          : currentColor,
      }}
      animate={{ backgroundColor: currentColor }}
      transition={{ duration: 3 }}
      className={classes.container}
    >
      {isLoading && (
        <RetryLoader
          isInstant
          onRetry={() =>
            dispatch(resetOrderStatus(order.state.BarcodeWithoutChecksum))
          }
        />
      )}

      {isNotFound && (
        <OrderCardNotFound
          variant="mobile"
          isSelected={isSelected}
          barcodeWithoutChecksum={order.state.BarcodeWithoutChecksum}
        />
      )}

      {hasError && (
        <Retry
          hintText={t("OrderCardMobile.error.hint")}
          buttonText={t("OrderCardMobile.error.buttonText")}
          onRetry={() => {
            dispatch(resetOrderStatus(order.state.BarcodeWithoutChecksum));
          }}
        />
      )}

      {hasData && (
        <OrderCardContentColumn
          order={order.state}
          variant="mobile"
          isMovedOrder={isStatus(order, "orderMoved")}
          isSelected={isSelected}
        />
      )}
    </motion.div>
  );
};

import {
  ICartAddModifierChoice,
  ICartLayout,
  ICartLineItem,
  ICartModifierChoices,
} from "@api/interfaces/cartLayouts";
import { IDiningOptionEnum } from "@api/interfaces/configurationLayouts";
import { IProduct } from "@api/interfaces/productLayouts";
import { isPresent } from "@apl-digital/utils";
import {
  getUnixCurrentDate,
  getUnixDateFromUTC,
} from "@base/utils/dateHelpers";
import {
  getSessionStorage,
  getStorage,
  SessionStorageKey,
  StorageKey,
} from "@constants/storage";
import { TheatreResourceState } from "@store/theatreResource";
import { isStatus } from "@store/utils";
import { format, isToday, isTomorrow } from "date-fns";

import {
  FreeModifier,
  MergedCartLineItem,
  MergedProductModifierChoice,
  PaidModifier,
  UnifiedCartLine,
  UnifiedCartLineModifier,
} from "./types";

export const isModifierChoiceComment = (
  modifierChoices: ICartModifierChoices,
): boolean => {
  return (
    modifierChoices.RelatedProductID === 0 &&
    modifierChoices.ProductModifierID === 0 &&
    modifierChoices.ProductModifierChoiceID === 0
  );
};

export const calculateModifierChoiceUniqueKey = (
  modifierChoice: ICartModifierChoices,
): string => {
  const keys: string[] = [
    String(modifierChoice.RelatedProductID),
    String(modifierChoice.ProductModifierID),
    String(modifierChoice.ProductModifierChoiceID),
  ];

  if (isModifierChoiceComment(modifierChoice)) {
    keys.push(modifierChoice.ProductModifierChoiceDisplayName);
  }

  return keys.sort().join("_");
};

export const calculateCartLineUniqueKey = (product: ICartLineItem): string => {
  const keys: string[] = [String(product.Product.ID)];

  if (isPresent(product.ProductModifierChoices)) {
    keys.push(
      ...product.ProductModifierChoices.map((modifierChoice) =>
        calculateModifierChoiceUniqueKey(modifierChoice),
      ),
    );
  }

  if (isPresent(product.VariableProductID)) {
    keys.push(String(product.VariableProductID));
  }

  return keys.sort().join("_");
};

export const groupObjects = <T>(
  calculateUniqueKey: (object: T) => string,
  objects: T[],
): Record<string, T[]> => {
  const groups = objects.reduce(
    (previousValue, currentValue) => {
      const keyValue = calculateUniqueKey(currentValue);

      if (!previousValue.hasOwnProperty(keyValue)) {
        previousValue[keyValue] = [currentValue];
      } else {
        previousValue[keyValue] = [...previousValue[keyValue], currentValue];
      }

      return previousValue;
    },
    {} as Record<string, T[]>,
  );

  return groups;
};

export const mergeProductModifierChoices = (
  uniqueKey: string,
  modifierChoices: ICartModifierChoices[],
): MergedProductModifierChoice => {
  if (modifierChoices.length < 1)
    throw new Error("No modifier choices to merge");

  if (
    modifierChoices.some(
      (modifier) =>
        isPresent(modifier.RelatedProductID) &&
        isPresent(modifier.RelatedProductShoppingCartLineID),
    )
  ) {
    return {
      type: "paid",
      uniqueKey,
      modifierChoiceId: modifierChoices[0].ProductModifierChoiceID,
      modifierId: modifierChoices[0].ProductModifierID,
      productId: modifierChoices[0].RelatedProductID,
      mergedCartLineIds: modifierChoices.map(
        (modifierChoice) => modifierChoice.RelatedProductShoppingCartLineID,
      ),
      amount: modifierChoices.reduce(
        (previousValue, currentValue) => previousValue + currentValue.Quantity,
        0,
      ),
      name: modifierChoices[0].ProductModifierChoiceDisplayName,
      description: modifierChoices[0].ProductModifierChoicePublicDescription,
    };
  }

  return {
    type: "free",
    uniqueKey,
    modifierChoiceId: modifierChoices[0].ProductModifierChoiceID,
    modifierId: modifierChoices[0].ProductModifierID,
    amount: modifierChoices.reduce(
      (previousValue, currentValue) => previousValue + currentValue.Quantity,
      0,
    ),
    name: modifierChoices[0].ProductModifierChoiceDisplayName,
    description: modifierChoices[0].ProductModifierChoicePublicDescription,
  };
};

export const mergeCartLines = (
  uniqueKey: string,
  cartLines: ICartLineItem[],
): MergedCartLineItem => {
  if (cartLines.length < 1) throw new Error("No cart lines to merge");

  return {
    uniqueKey: uniqueKey,
    productId: cartLines[0].Product.ID,
    variableProductId: cartLines[0].VariableProductID,
    name: cartLines[0].Name,
    amount: cartLines.reduce(
      (previousValue, currentValue) => previousValue + currentValue.Amount,
      0,
    ),
    grossSum: cartLines.reduce(
      (previousValue, currentValue) => previousValue + currentValue.GrossSum,
      0,
    ),
    isPackagingItem: cartLines[0].ProductPackagingSetConstraintID === 1,
    noteToKitchen: cartLines[0].ProductModifierChoices?.find(
      (modifier) => modifier.ProductModifierChoiceID === 0,
    )?.ProductModifierChoiceDisplayName,
    mergedCartLineIds: cartLines.map((line) => line.ID),
    relatedChildCartLineIds: cartLines.flatMap((line) => [
      ...(line.RelatedChildShoppingCartLines?.map((l) => l.ID) ?? []), // OA-1659: This is a temporary fix for the issue where the OLD FC api doesn't return any related child shopping cart lines
    ]),
  };
};

export const mapPaidUnifiedCartLineModifier = (
  cartLines: ICartLineItem[],
  mergedModifierChoice: PaidModifier,
): UnifiedCartLineModifier => {
  const modifierCartLines = cartLines.filter((line) =>
    mergedModifierChoice.mergedCartLineIds.includes(line.ID),
  );

  const grossSum = modifierCartLines[0].GrossSum;

  const isValid = modifierCartLines.every((line) => line.GrossSum === grossSum);

  if (!isValid) {
    console.error("Invalid gross sum for paid modifier choice", {
      grossSum,
      modifierCartLines,
    });
  }

  return {
    type: "choice",
    uniqueKey: mergedModifierChoice.uniqueKey,
    name: mergedModifierChoice.name,
    description: mergedModifierChoice.description,
    amount: mergedModifierChoice.amount,
    grossSum: grossSum,
    modifierId: mergedModifierChoice.modifierId,
    modifierChoiceId: mergedModifierChoice.modifierChoiceId,
  };
};

export const mapFreeUnifiedCartLineModifier = (
  mergedModifierChoice: FreeModifier,
): UnifiedCartLineModifier => {
  return {
    type: "choice",
    uniqueKey: mergedModifierChoice.uniqueKey,
    name: mergedModifierChoice.name,
    description: mergedModifierChoice.description,
    amount: mergedModifierChoice.amount,
    grossSum: 0,
    modifierId: mergedModifierChoice.modifierId,
    modifierChoiceId: mergedModifierChoice.modifierChoiceId,
  };
};

export const unifyCartLineModifierChoices = (
  cartLines: ICartLineItem[],
  mergedModifierChoice: MergedProductModifierChoice,
): UnifiedCartLineModifier => {
  if (mergedModifierChoice.type === "paid") {
    return mapPaidUnifiedCartLineModifier(cartLines, mergedModifierChoice);
  }

  return mapFreeUnifiedCartLineModifier(mergedModifierChoice);
};

export const unifyCartLine = (
  mergedProduct: MergedCartLineItem,
  unifiedModifierChoices: UnifiedCartLineModifier[],
): UnifiedCartLine => {
  const totalGrossSum =
    mergedProduct.grossSum +
    unifiedModifierChoices.reduce((acc, modifier) => {
      if (modifier.type === "choice") {
        return acc + modifier.grossSum * modifier.amount;
      }

      return acc;
    }, 0);

  return {
    ...mergedProduct,
    totalGrossSum: totalGrossSum,
    modifiers: unifiedModifierChoices,
  };
};

export const mapCartLineToUnifiedModifier = (
  cartLines: ICartLineItem[],
  cartLineId: number,
): UnifiedCartLineModifier => {
  const cartLine = cartLines.find((line) => line.ID === cartLineId);

  if (!cartLine) throw new Error("Cart line not found");

  return {
    type: "extra",
    uniqueKey: String(cartLine.ID),
    name: cartLine.Name,
    amount: cartLine.Amount,
    grossSum: cartLine.GrossSum,
  };
};

export const getProductMinRequiredAge = (product: IProduct): number => {
  return Math.max(
    product?.AlcoholContent?.MinRequiredAge || 0,
    product?.SalesMessageCategory?.MinRequiredAge || 0,
  );
};

export const getHighestMinRequiredAge = (
  products: IProduct[] | undefined,
): number => {
  if (!isPresent(products)) return 0;

  const minRequiredAges = products.map((product) =>
    getProductMinRequiredAge(product),
  );

  if (minRequiredAges.length === 0) return 0;

  return Math.max(...minRequiredAges);
};

export const getCartModifierIds = (cartModifiers: ICartAddModifierChoice[]) =>
  cartModifiers.map((m) => {
    if (!m.ProductId || m.ProductId === 0) return m.ProductModifierChoiceID;
    return m.ProductId;
  });

export const shouldPromptTableInfo = (
  shoppingCart: ICartLayout | null | undefined,
  tableInfo: TheatreResourceState["theatreResource"],
) => {
  const barcode = getSessionStorage(SessionStorageKey.THEATER_RESOURCE);
  const cart = getStorage(StorageKey.CART);

  const isCartForTakeOut =
    shoppingCart?.Defaults?.DiningOptionID === IDiningOptionEnum.TAKE_OUT;

  const isPreorder = !!shoppingCart?.Defaults?.dttmRequestedDelivery;

  return !(
    (cart && barcode) ||
    isCartForTakeOut ||
    isStatus(tableInfo, ["succeeded", "stale"]) ||
    isPreorder
  );
};

export const formatDeliveryDateAndTime = (
  date: Date,
  todayTranslation: string,
  tomorrowTranslation: string,
) =>
  format(
    date,
    `HH:mm ${
      isToday(date)
        ? `'${todayTranslation},'`
        : isTomorrow(date)
          ? `'${tomorrowTranslation},'`
          : ""
    } dd.MM.yyyy`,
  );

export const isShoppingCartExpired = (
  shoppingCart: ICartLayout,
): boolean | undefined => {
  if (shoppingCart?.dttmExpiresUTC) {
    const expiryDate = getUnixDateFromUTC(shoppingCart.dttmExpiresUTC);
    const nowDate = getUnixCurrentDate();

    return nowDate - expiryDate > 0;
  }

  return;
};

import { DiningOption } from "@api/interfaces/configurationLayouts";
import { isPresent } from "@apl-digital/utils";
import { createSelector } from "@reduxjs/toolkit";
import { hasData } from "@store/apiRequestStatusMachine";
import {
  selectDefaultDiningOption,
  selectDiningOption,
  selectIsPreOrderAvailable,
} from "@store/salesPoint";
import { RootState } from "@store/slices";
import { EMPTY_ARRAY } from "@store/utils";

import {
  calculateCartLineUniqueKey,
  calculateModifierChoiceUniqueKey,
  getHighestMinRequiredAge,
  groupObjects,
  isModifierChoiceComment,
  mapCartLineToUnifiedModifier,
  mergeCartLines,
  mergeProductModifierChoices,
  unifyCartLine,
  unifyCartLineModifierChoices,
} from "./helper";
import {
  selectShoppingCart,
  selectShoppingCartLines,
  selectShoppingCartSums,
} from "./slice";
import { UnifiedCartLine, UnifiedCartLineModifier } from "./types";

export const selectShoppingCartProducts = createSelector(
  [selectShoppingCartLines, (state, productIds?: number[]) => productIds],
  (shoppingCartLines, productIds) => {
    const lines =
      shoppingCartLines?.filter(
        (line) => line.RelatedShoppingCartLineID === 0,
      ) || EMPTY_ARRAY;

    if (productIds) {
      return lines.filter((line) => productIds.includes(line.Product.ID));
    }

    return lines;
  },
);

export const selectShoppingCartUnifiedProducts = createSelector(
  [selectShoppingCartLines, selectShoppingCartProducts],
  (shoppingCartLines, productCartLines): UnifiedCartLine[] => {
    const unifiedCartLines: UnifiedCartLine[] = [];

    const groupedProducts = groupObjects(
      calculateCartLineUniqueKey,
      productCartLines,
    );

    for (const [uniqueKey, group] of Object.entries(groupedProducts)) {
      const mergedProduct = mergeCartLines(uniqueKey, group);

      const filteredProductModifierChoices = group
        .flatMap((item) => item.ProductModifierChoices)
        .filter(isPresent)
        .filter((item) => !isModifierChoiceComment(item));

      const groupedModifierChoices = groupObjects(
        calculateModifierChoiceUniqueKey,
        filteredProductModifierChoices,
      );

      const unifiedModifierChoices: UnifiedCartLineModifier[] = [];

      for (const [
        modifierChoiceUniqueKey,
        modifierChoiceGroup,
      ] of Object.entries(groupedModifierChoices)) {
        const mergedModifierChoice = mergeProductModifierChoices(
          modifierChoiceUniqueKey,
          modifierChoiceGroup,
        );

        const unifiedModifierChoice = unifyCartLineModifierChoices(
          shoppingCartLines || EMPTY_ARRAY,
          mergedModifierChoice,
        );

        unifiedModifierChoices.push(unifiedModifierChoice);
      }

      const modifierChoicesCartLineIds = Object.values(
        groupedModifierChoices,
      ).flatMap((modifierChoiceGroup) =>
        modifierChoiceGroup.map(
          (item) => item.RelatedProductShoppingCartLineID,
        ),
      );

      const extraModifierCartLines = mergedProduct.relatedChildCartLineIds
        .filter((id) => !modifierChoicesCartLineIds.includes(id))
        .map((id) =>
          mapCartLineToUnifiedModifier(shoppingCartLines || EMPTY_ARRAY, id),
        );
      unifiedModifierChoices.push(...extraModifierCartLines);

      unifiedCartLines.push(
        unifyCartLine(mergedProduct, unifiedModifierChoices),
      );
    }

    if (unifiedCartLines.length === 0) {
      return EMPTY_ARRAY;
    }

    return unifiedCartLines;
  },
);

export const selectShoppingCartTotalItemCount = createSelector(
  [
    selectShoppingCartLines,
    (state, excludedProductIds: number[] = EMPTY_ARRAY) => excludedProductIds,
  ],
  (lines, excludedProductIds) => {
    if (!isPresent(lines)) {
      return 0;
    }

    return lines
      .filter((line) => line.RelatedShoppingCartLineID === 0)
      .filter((line) => !excludedProductIds.includes(line.Product.ID)).length;
  },
);

export const selectShoppingCartTotal = (state: RootState) =>
  selectShoppingCartSums(state)?.LineGrossSum || 0;

export const selectCurrentDiningOption = (
  state: RootState,
): DiningOption | null => {
  const shoppingCart = selectShoppingCart(state);

  if (
    hasData(shoppingCart) &&
    isPresent(shoppingCart.state.Defaults?.DiningOptionID)
  ) {
    const shoppingCartDiningOption = selectDiningOption(
      state,
      shoppingCart.state.Defaults.DiningOptionID,
    );

    if (isPresent(shoppingCartDiningOption)) {
      return shoppingCartDiningOption;
    }
  }

  const defaultDiningOption = selectDefaultDiningOption(state);

  if (isPresent(defaultDiningOption)) {
    return defaultDiningOption;
  }

  return null;
};

export const selectShoppingCartDingingOption = (state: RootState) => {
  const shoppingCart = selectShoppingCart(state);

  if (hasData(shoppingCart)) {
    return shoppingCart.state.Defaults?.DiningOptionID;
  }

  return null;
};

export const selectIsShoppingCartPreOrderAvailable = (state: RootState) => {
  const shoppingCartDiningOption = selectShoppingCartDingingOption(state);

  if (!isPresent(shoppingCartDiningOption)) {
    return false;
  }

  return selectIsPreOrderAvailable(state, shoppingCartDiningOption);
};

export const selectMinRequiredAge = createSelector(
  [selectShoppingCartProducts],
  (products) => {
    return getHighestMinRequiredAge(products.map((p) => p.Product));
  },
);

export const selectShoppingCartPreOrderDeliveryData = createSelector(
  selectShoppingCart,
  (shoppingCart) => {
    if (!hasData(shoppingCart)) {
      return null;
    }

    if (isPresent(shoppingCart.state.Defaults?.dttmRequestedDelivery)) {
      return new Date(shoppingCart.state.Defaults.dttmRequestedDelivery);
    }

    return null;
  },
);

export const selectShoppingCartItemCount = createSelector(
  [selectShoppingCartLines],
  (shoppingCartLines): number => {
    if (!isPresent(shoppingCartLines)) {
      return 0;
    }

    return shoppingCartLines
      .filter((line) => line.RelatedShoppingCartLineID === 0)
      .reduce((acc, i) => acc + i.Amount, 0);
  },
);

export const selectShoppingCartItemCountByProductIds = createSelector(
  [selectShoppingCartLines, (state, productIds: number[]) => productIds],
  (shoppingCartLines, productIds): number => {
    if (!isPresent(shoppingCartLines)) {
      return 0;
    }

    return shoppingCartLines
      .filter((line) => line.RelatedShoppingCartLineID === 0)
      .filter((i) => productIds.includes(i.Product.ID))
      .reduce((acc, i) => acc + i.Amount, 0);
  },
);

import React, { useEffect, useRef } from "react";
import { useAuth } from "react-oidc-context";
import SignedInBlock from "@base/components/LoyaltyClub/SignedInBlock";
import SignInBlock from "@base/components/LoyaltyClub/SignInBlock";
import { NavigationPath } from "@constants/navigation";
import { useUser } from "@context/UserProvider";
import ContactDetailsFormProvider from "@forms/contactDetails/ContactDetailsFormProvider";
import { useAppSelector } from "@store";
import { selectShouldShowPaymentErrorForCurrentCart } from "@store/payment/selectors";

import { SECTIONS_SELECTOR_HEIGHT } from "./ProductList";
import { ShoppingCartCard } from "./ShoppingCartCard";

const CartSection: React.FC = () => {
  const cartRef = useRef<HTMLDivElement>(null);

  const shouldShowPaymentError = useAppSelector(
    selectShouldShowPaymentErrorForCurrentCart,
  );

  const { isAuthenticated } = useAuth();
  const { shouldShowSignIn } = useUser();

  useEffect(() => {
    if (!shouldShowPaymentError) return;

    const timeout = setTimeout(() => {
      if (!cartRef.current) return;
      window.scrollTo({
        top: cartRef.current.offsetTop - SECTIONS_SELECTOR_HEIGHT,
        behavior: "smooth",
      });
    }, 700);

    return () => {
      clearTimeout(timeout);
    };
  }, [shouldShowPaymentError]);

  // for Google Analytics
  const isOrderStatusPage = window.location.pathname.includes(
    NavigationPath.OrderBase,
  );
  const isHomePage = window.location.pathname === NavigationPath.Home;
  const isCartPage = window.location.pathname === NavigationPath.Cart;
  const pageName =
    (isHomePage && "homepage") ||
    (isOrderStatusPage && "order-status-page") ||
    (isCartPage && "cart-page") ||
    "";

  return (
    <>
      {shouldShowSignIn && (
        <SignInBlock buttonId={pageName && `sign-in-${pageName}`} />
      )}

      {isAuthenticated && <SignedInBlock variant="filled" />}

      <ShoppingCartCard />
    </>
  );
};

const CartSectionWithContactForm = () => {
  return (
    <ContactDetailsFormProvider>
      <CartSection />
    </ContactDetailsFormProvider>
  );
};

export default CartSectionWithContactForm;

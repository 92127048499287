import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { useAuth } from "react-oidc-context";
import { useNavigate } from "react-router-dom";
import Icon from "@base/components/Global/Icon";
import Loader from "@base/components/Global/Loader";
import useEffectAsync from "@base/hooks/useEffectAsync";
import { isInIframe } from "@base/utils/iframeUtils";
import { NavigationPath } from "@constants/navigation";
import { clearStorage, getStorage, StorageKey } from "@constants/storage";
import { getBlobStorage } from "@theme";

const useStyles = createUseStyles(({ font, spacing }) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    margin: spacing.xxl,
    gap: spacing.x3l,
    paddingTop: spacing.xl,
  },
  header: {
    fontSize: font.size.l,
    fontWeight: font.weight.l,
    textAlign: "center",
    lineHeight: font.lineHeight.m,
  },
}));

const AuthLandingPage: React.FC = () => {
  const { error, isAuthenticated, isLoading, user, signinSilent } = useAuth();
  const { t } = useTranslation("authLandingPage");
  const navigate = useNavigate();
  const classes = useStyles();
  const { failIcon, successGrayIcon } = getBlobStorage();
  const [hasTriedSilentSignin, setHasTriedSilentSignin] = useState(false);

  const navigateAway = () => {
    if (error && !isAuthenticated && !user) {
      window.open(window.origin, "_self");
    }

    const authPreviousPage = getStorage(
      StorageKey.AUTH_PREV_PAGE,
      NavigationPath.Home,
    );

    clearStorage(StorageKey.AUTH_PREV_PAGE);

    navigate(authPreviousPage, { replace: true });
  };

  useEffect(() => {
    if (isLoading || isInIframe()) {
      return;
    }

    const timeout = setTimeout(() => navigateAway(), 3000);

    return () => clearTimeout(timeout);
  }, [isLoading]);

  useEffectAsync(async () => {
    if (error) {
      console.error("Authentication failed: ", error);

      if (!hasTriedSilentSignin) {
        await signinSilent();
        setHasTriedSilentSignin(true);
      }
    }
  }, [error, hasTriedSilentSignin]);

  if (!isLoading && error) {
    return (
      <div className={classes.container}>
        <div className={classes.header}>{t("login.failed")}</div>
        <div>
          <Icon width={100} height={100} url={failIcon} />
        </div>
      </div>
    );
  }

  if (!isLoading && isAuthenticated) {
    return (
      <div className={classes.container}>
        <div>
          <Icon width={100} height={100} url={successGrayIcon} />
        </div>
      </div>
    );
  }

  return <Loader isFullScreen />;
};

export default AuthLandingPage;

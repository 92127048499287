import React, { createContext, useContext, useEffect } from "react";
import { hasAuthParams, useAuth } from "react-oidc-context";
import { isPresent } from "@apl-digital/utils";
import useEffectAsync from "@base/hooks/useEffectAsync";
import { isInIframe } from "@base/utils/iframeUtils";
import config from "@constants/config";
import { setStorage, StorageKey } from "@constants/storage";
import { useAppDispatch } from "@store/hooks";
import { showLoginModal } from "@store/layout";
import { logInWithID, resetUser } from "@store/user";

import { UserProviderProps, UserProviderResults } from "./types";
import { isSSOEnabled } from "./utils";

const UserContext = createContext({} as UserProviderResults);

export const useUser = () => useContext(UserContext);

export const UserProvider: React.FC<UserProviderProps> = ({ children }) => {
  const dispatch = useAppDispatch();
  const auth = useAuth();
  const [hasTriedSilentSignin, setHasTriedSilentSignin] = React.useState(false);

  useEffect(() => {
    if (
      auth.isAuthenticated &&
      isPresent(auth.user) &&
      isPresent(auth.user.profile.mcs_customer_guid)
    ) {
      dispatch(
        logInWithID({
          body: {
            AuthenticationMethod: 6,
            Credentials: {
              UserID: auth.user.profile.mcs_customer_guid,
            },
            Application: 0,
          },
        }),
      );
    }
  }, [auth.isAuthenticated]);

  useEffectAsync(async () => {
    if (
      !isInIframe() &&
      !hasAuthParams() &&
      !auth.isAuthenticated &&
      !auth.activeNavigator &&
      !auth.isLoading &&
      !hasTriedSilentSignin &&
      config.featureFlags.isLoginEnabled &&
      isSSOEnabled()
    ) {
      await auth.signinSilent();
      setHasTriedSilentSignin(true);
    }
  }, [auth, hasTriedSilentSignin]);

  const login = async () => {
    if (!config.featureFlags.isLoginEnabled) {
      return;
    }

    setStorage(StorageKey.AUTH_PREV_PAGE, window.location.pathname);

    if (isSSOEnabled()) {
      if (!auth.isAuthenticated && !auth.isLoading && hasTriedSilentSignin) {
        await auth.signinRedirect();
      }
    } else {
      dispatch(showLoginModal());
    }
  };

  const logout = async () => {
    dispatch(resetUser());

    if (isSSOEnabled()) {
      await auth.signoutRedirect({
        post_logout_redirect_uri: window.location.origin,
      });
    }
  };

  const shouldShowSignIn =
    !auth.isAuthenticated && config.featureFlags.isLoginEnabled;

  return (
    <UserContext.Provider
      value={{
        hasTriedSilentSignin,
        login,
        logout,
        shouldShowSignIn,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IAvailableDiningTime } from "@api/interfaces/configurationLayouts";
import { isPresent } from "@apl-digital/utils";
import PreorderTimeSelection from "@base/components/Cart/PreorderTimeSelection";
import { useModalContext } from "@context/ModalContainer";
import { useAppDispatch, useAppSelector } from "@store";
import { hasData } from "@store/apiRequestStatusMachine";
import { initializeDiningCart, selectShoppingCart } from "@store/shoppingCart";
import { selectUserId } from "@store/user";

const PreorderTimeSelectionWrapper: React.FC<{
  onCartCreate: () => void;
  datesRefetchTrigger: boolean;
}> = ({ onCartCreate, datesRefetchTrigger }) => {
  const [selectedTimeObject, setSelectedTimeObject] =
    useState<IAvailableDiningTime | null>(null);
  const shoppingCart = useAppSelector(selectShoppingCart);
  const userId = useAppSelector(selectUserId);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { showConfirmationModal } = useModalContext();

  const createNewCart = async () => {
    if (
      !hasData(shoppingCart) ||
      !shoppingCart.state.Defaults?.DiningOptionID
    ) {
      return;
    }

    if (!selectedTimeObject) {
      throw new Error(
        "Cannot create a dining option cart if selected time object is undefined",
      );
    }

    try {
      await dispatch(
        initializeDiningCart({
          theatreResourceId: selectedTimeObject.TheatreResourceID,
          dttmRequestedDelivery: selectedTimeObject.dttmDelivery,
          diningOptionId: shoppingCart.state.Defaults.DiningOptionID,
          customerPersonId: userId,
        }),
      );

      onCartCreate();
    } catch (e) {
      throw e;
    }
  };

  useEffect(() => {
    if (!isPresent(selectedTimeObject)) {
      return;
    }

    showConfirmationModal({
      title: t("delivery_time_change_confirmation_modal_title"),
      body: t("delivery_time_change_confirmation_modal_body"),
      confirmationCallback: createNewCart,
    });
  }, [selectedTimeObject]);

  if (!hasData(shoppingCart)) {
    return null;
  }

  return (
    <>
      <PreorderTimeSelection
        showFieldLabels
        allowUndefinedTimeValue
        datesRefetchTrigger={datesRefetchTrigger}
        selectedTimeObject={selectedTimeObject}
        setSelectedTimeObject={setSelectedTimeObject}
        selectedDiningOption={shoppingCart.state.Defaults?.DiningOptionID}
      />
    </>
  );
};

export default PreorderTimeSelectionWrapper;

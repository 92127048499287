import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { Retry } from "@base/components/Global/Retry";
import { RetryLoader } from "@base/components/Global/RetryLoader";
import useWindowSize from "@base/hooks/useWindowSize";
import { useAppDispatch } from "@store";
import {
  OrderDataWithGroupingStatusMachine,
  resetOrderStatus,
} from "@store/orders";
import { isStatus } from "@store/utils";
import { getBrandTheme } from "@theme";
import classNames from "classnames";
import { motion } from "framer-motion";

import { OrderCardContentColumn } from "./OrderCardContentColumn";
import { OrderCardContentRow } from "./OrderCardContentRow";
import { OrderCardNotFound } from "./OrderCardNotFound";

const useStyles = createUseStyles(({ borderRadius, color, spacing }) => ({
  container: {
    width: "90vw",
    borderRadius: borderRadius.xl,
    padding: [spacing.x4l, spacing.x5l, spacing.x4l, spacing.x5l],
    backgroundColor: color.orderStatusCardInactiveBackgroundColor,
  },
  column: {
    flexBasis: `calc(50% - ${spacing.xxl / 2}px)`,
  },
  row: {
    flexBasis: "100%",
  },
}));

type OrderCardDesktopProps = {
  order: OrderDataWithGroupingStatusMachine;
  preferredVariant: "column" | "row";
  isSelected?: boolean;
};

export const OrderCardDesktop: React.FC<OrderCardDesktopProps> = ({
  order,
  preferredVariant,
  isSelected = false,
}) => {
  const classes = useStyles();
  const { isSm } = useWindowSize();
  const dispatch = useAppDispatch();
  const { t } = useTranslation("orderStatusPage");

  const ref = React.useRef<HTMLDivElement>(null);

  const { color } = getBrandTheme();

  useEffect(() => {
    if (!isSelected) return;

    const timeout = setTimeout(() => {
      ref.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }, 250);

    return () => clearTimeout(timeout);
  }, [isSelected]);

  let variant = preferredVariant;

  if (isSm) {
    variant = "column";
  }

  const isLoading = isStatus(order, ["idle", "pending"]);
  const hasError = isStatus(order, ["failed", "failedPending"]);
  const hasData = isStatus(order, [
    "stale",
    "stalePending",
    "succeeded",
    "orderMoved",
  ]);
  const isNotFound = isStatus(order, ["notFound"]);

  let currentColor = color.orderStatusCardInactiveBackgroundColor;

  if (hasData) {
    if (isStatus(order, "orderMoved")) {
      currentColor = color.orderStatusCardInactiveBackgroundColor;
    } else if (!order.state.isComplete) {
      if (order.state.isPaid) {
        currentColor = color.orderStatusCardPaidBackgroundColor;
      } else {
        currentColor = color.orderStatusCardPendingBackgroundColor;
      }
    }
  }

  return (
    <motion.div
      ref={ref}
      initial={{
        backgroundColor: isSelected
          ? color.orderStatusCardBackgroundHighlight
          : currentColor,
      }}
      animate={{ backgroundColor: currentColor }}
      transition={{ duration: 3 }}
      className={classNames(classes.container, classes[variant])}
    >
      {isLoading && (
        <RetryLoader
          isInstant
          onRetry={() =>
            dispatch(resetOrderStatus(order.state.BarcodeWithoutChecksum))
          }
        />
      )}

      {isNotFound && (
        <OrderCardNotFound
          variant="desktop"
          isSelected={isSelected}
          barcodeWithoutChecksum={order.state.BarcodeWithoutChecksum}
        />
      )}

      {hasError && (
        <Retry
          hintText={t("OrderCardDesktop.error.hint")}
          buttonText={t("OrderCardDesktop.error.buttonText")}
          onRetry={() => {
            dispatch(resetOrderStatus(order.state.BarcodeWithoutChecksum));
          }}
        />
      )}

      {hasData && (
        <>
          {variant === "row" ? (
            <OrderCardContentRow
              order={order.state}
              isMovedOrder={isStatus(order, "orderMoved")}
              isSelected={isSelected}
            />
          ) : (
            <OrderCardContentColumn
              order={order.state}
              variant="desktop"
              isMovedOrder={isStatus(order, "orderMoved")}
              isSelected={isSelected}
            />
          )}
        </>
      )}
    </motion.div>
  );
};

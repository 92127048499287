import React from "react";
import { createUseStyles } from "react-jss";
import classnames from "classnames";

import Loader from "./Loader";

const useStyles = createUseStyles(
  ({ color, borderRadius, spacing, font, sizes, config }) => ({
    "@keyframes spin": {
      from: {
        transform: "rotate(0deg)",
      },
      to: {
        transform: "rotate(360deg)",
      },
    },
    loader: {
      animation: "$spin 3s infinite linear",
      display: "flex",
    },
    btn: {
      width: "100%",
      backgroundColor: color.buttonBg,
      color: color.buttonText,
      height: config.buttonHeight,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      gap: spacing.xxl,
      borderRadius: borderRadius.button,
      border: "none",
      cursor: "pointer",
      padding: [spacing.m, spacing.xxl],
      [sizes.smallWidth]: {
        gap: 0,
        lineHeight: font.lineHeight.x3s,
      },
      fontWeight: font.weight.m,
      lineHeight: font.lineHeight.m,
      fontSize: font.size.l,
      userSelect: "none",
      justifyContent: "center",
    },
    navbarBtn: {
      color: color.navbarDiningMethodButtonText,
      fontSize: font.size.s,
      lineHeight: font.lineHeight.s,
      height: 38,
      width: "auto",
      padding: [spacing.xxl, spacing.xxl],
      background: color.navbarDiningMethodButtonBg,
      borderRadius: borderRadius.button,
      textAlign: "center",
      display: "flex",
      alignSelf: "start",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "clip",
      alignItems: "center",
      gap: spacing.xs,
      fontStyle: "normal",
      fontWeight: font.weight.m,
      margin: [0, spacing.xs],
    },
    transparent: {
      backgroundColor: color.buttonTransparentBg,
      color: color.buttonTransparentText,
      border: `1px solid ${color.buttonTransparentBorder}`,
    },
    fitContent: {
      width: "fit-content",
      paddingLeft: spacing.x5l,
      paddingRight: spacing.x5l,
    },
    menu: {
      width: config.buttonHeight,
      borderRadius: borderRadius.button,
      fontSize: font.size.x4s,
      fontWeight: font.weight.l,
      padding: 0,
      flexShrink: 0,
      justifyContent: "center",
    },
    disabled: {
      backgroundColor: color.disabledButtonBg,
      color: color.disabledButtonText,
    },
  }),
);

export enum BUTTON_MODS {
  NAVBAR = "navbar",
}

type Props = {
  action: () => void;
  disabledAction?: () => void;
  children?: string | React.ReactNode;
  isDisabled?: boolean;
  isMenu?: boolean;
  className?: React.ReactNode;
  id?: string;
  isTransparent?: boolean;
  fitContent?: boolean;
  isLoading?: boolean;
  mode?: BUTTON_MODS;
};

const Button: React.FC<Props> = ({
  action,
  disabledAction,
  children,
  isDisabled = false,
  isMenu = false,
  className,
  id = "btn",
  isTransparent = false,
  fitContent = false,
  isLoading = false,
  mode,
}) => {
  const classes = useStyles();

  return (
    <div
      id={`${id}${isDisabled ? "-disabled" : ""}`}
      onClick={() => {
        if (isLoading) return;

        if (isDisabled) {
          disabledAction?.();
        } else {
          action();
        }
      }}
      className={classnames([
        classes.btn,
        isDisabled && classes.disabled,
        isMenu && classes.menu,
        isTransparent && classes.transparent,
        fitContent && classes.fitContent,
        mode === BUTTON_MODS.NAVBAR && classes.navbarBtn,
        className,
      ])}
    >
      {isLoading ? (
        <div className={classes.loader}>
          <Loader isRaw isInstant size="24px" />
        </div>
      ) : (
        children
      )}
    </div>
  );
};

export default Button;

import React from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { generatePath, useNavigate } from "react-router-dom";
import { OrderLineStatus } from "@api/interfaces/orderLayouts";
import config from "@constants/config";
import { NavigationPath } from "@constants/navigation";
import { Order } from "@store/orders/types";
import { isStatus } from "@store/utils";
import { getBlobStorage } from "@theme";
import classNames from "classnames";

import Icon from "../Global/Icon";
import Loader from "../Global/Loader";

import { useSharedStyles } from "./shared.style";

const useStyles = createUseStyles({
  lastItem: {
    marginLeft: "auto",
    flexShrink: 0,
  },
});

type BurgerMenuMyOrderLineProps = {
  order: Order;
  closeBurgerMenu: () => void;
};

export const BurgerMenuMyOrderLine: React.FC<BurgerMenuMyOrderLineProps> = ({
  order,
  closeBurgerMenu,
}) => {
  const sharedClasses = useSharedStyles();
  const classes = useStyles();
  const navigate = useNavigate();
  const { t } = useTranslation("global");

  const { extraChoiceIcon, orderPendingIcon, warningIcon } = getBlobStorage();

  const onLineClick = () => {
    navigate(
      generatePath(NavigationPath.OrderStatus, {
        orderID: order.state.BarcodeWithoutChecksum,
      }),
    );

    closeBurgerMenu();
  };

  let statusIcon = extraChoiceIcon;
  const isLoading = isStatus(order, ["idle", "pending"]);

  if (
    !isLoading &&
    isStatus(order, ["failed", "failedPending", "notFound", "orderMoved"])
  ) {
    statusIcon = warningIcon;
  } else if (
    !isLoading &&
    config.kdsConfig.isEnabled &&
    order.state.Lines.some(
      (line) =>
        line.Status !== OrderLineStatus.ReadyForDelivery &&
        line.Status !== OrderLineStatus.Delivered,
    )
  ) {
    statusIcon = orderPendingIcon;
  }

  return (
    <div
      className={classNames([
        sharedClasses.menuItemSubMenu,
        "burger-menu-finished-order-btn", // for Google Analytics
      ])}
      key={order.state.BarcodeWithoutChecksum}
      onClick={onLineClick}
    >
      {isLoading ? (
        <Loader size="24px" isRaw />
      ) : (
        <Icon url={statusIcon} height={24} width={24} />
      )}

      <div>
        {isStatus(order, ["stale", "stalePending", "succeeded", "orderMoved"])
          ? t("BurgerMenuMyOrderLine.orderNumber.transactionId", {
              transactionId: order.state.TransactionID,
            })
          : t("BurgerMenuMyOrderLine.orderNumber.barcodeWithoutChecksum", {
              barcodeWithoutChecksum: order.state.BarcodeWithoutChecksum,
            })}
      </div>

      {isStatus(order, ["stale", "stalePending", "succeeded"]) && (
        <div className={classes.lastItem} id="my-order-price">
          {t("BurgerMenuMyOrderLine.orderTotalGrossSum", {
            orderTotalGrossSum: order.state.TotalGrossSum,
            formatParams: {
              orderTotalGrossSum: {
                currency: config.appConfig.currency.currencyCode,
                locale: config.appConfig.currency.formatLocale,
              },
            },
          })}
        </div>
      )}
    </div>
  );
};

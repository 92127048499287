import React, { useEffect, useRef } from "react";
import { createUseStyles } from "react-jss";
import { useKey } from "react-use";
import useOnClickOutside from "@base/hooks/useOnClickOutside";
import { MODAL_ANIMATION_DURATION } from "@constants/misc";
import { useAppDispatch } from "@store";
import { setIsRootScrollbarVisible } from "@store/layout";
import { getBlobStorage, getBrandTheme } from "@theme";
import classNames from "classnames";
import { AnimatePresence, motion } from "framer-motion";

import Icon from "./Icon";
import { ModalRoot } from "./ModalRoot";

const useStyles = createUseStyles(({ borderRadius, color, spacing }) => ({
  overlay: {
    zIndex: 90,
    position: "fixed",
    inset: 0,
    background: color.modalOverlay,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backdropFilter: "blur(4px)",
    "-webkit-backdrop-filter": "blur(4px)",
  },
  center: {
    zIndex: 91,
    position: "fixed",
    inset: 0,
    width: "100vw",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  highestZIndex: {
    zIndex: 99,
  },
  contentContainer: {
    maxWidth: `calc(100vw - ${spacing.x5l}px)`,
    maxHeight: `calc(100vh - ${spacing.x5l}px)`,
    backgroundColor: color.modalBg,
    borderRadius: borderRadius.x3l,
    pointerEvents: "auto",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
  },
  floatingCloseButton: {
    position: "absolute",
    top: spacing.xxl,
    zIndex: 1,
    right: spacing.x2l,
    borderRadius: 1000,
    background: "rgba(255, 255, 255, 0.5)",
    justifyContent: "center",
    cursor: "pointer",
    display: "flex",

    "@media (hover: hover)": {
      "&:hover": {
        background: "rgba(190, 190, 190, 0.5)",
      },
    },
  },
}));

const overlayVariants = {
  hidden: {
    backdropFilter: "blur(0px)",
  },
  visible: {
    backdropFilter: "blur(4px)",
  },
};

const contentVariants = {
  hidden: {
    transform: "translateY(40px)",
    opacity: 0,
  },
  visible: {
    transform: "translateY(0)",
    opacity: 1,
  },
};

const transition = { duration: MODAL_ANIMATION_DURATION / 1000 };

type ModalContentProps = {
  showCloseButton?: boolean;
  width?: string;
  forceToForeground?: boolean;
  allowCloseOnOutsideClick?: boolean;
  allowCloseOnEscape?: boolean;
  onClose: () => void;
  children?: React.ReactNode;
  shouldHideRootScrollbar?: boolean;
};

const ModalContent: React.FC<ModalContentProps> = ({
  showCloseButton = true,
  onClose,
  width,
  forceToForeground = false,
  allowCloseOnOutsideClick = false,
  allowCloseOnEscape = false,
  shouldHideRootScrollbar = false,
  children,
}) => {
  const overlayRef = useRef<HTMLDivElement>(null);
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { color } = getBrandTheme();
  const { closeIcon } = getBlobStorage();
  const contentContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (shouldHideRootScrollbar) dispatch(setIsRootScrollbarVisible(false));

    return () => {
      if (shouldHideRootScrollbar) dispatch(setIsRootScrollbarVisible(true));
    };
  }, []);

  useOnClickOutside(contentContainerRef, () => {
    if (allowCloseOnOutsideClick) onClose();
  });

  useKey("Escape", () => {
    if (allowCloseOnEscape) onClose();
  });

  return (
    <div>
      <motion.div
        className={classNames(
          classes.overlay,
          forceToForeground && classes.highestZIndex,
        )}
        ref={overlayRef}
        variants={overlayVariants}
        initial="hidden"
        animate="visible"
        exit="hidden"
        transition={transition}
      />
      <div
        className={classNames(
          classes.center,
          forceToForeground && classes.highestZIndex,
        )}
      >
        <motion.div
          className={classes.contentContainer}
          ref={contentContainerRef}
          variants={contentVariants}
          initial="hidden"
          animate="visible"
          exit="hidden"
          transition={transition}
          style={{ width }}
        >
          {showCloseButton && (
            <span
              role="button"
              className={classes.floatingCloseButton}
              onClick={onClose}
            >
              <Icon
                height={32}
                width={32}
                url={closeIcon}
                stroke={color.black}
              />
            </span>
          )}
          {children}
        </motion.div>
      </div>
    </div>
  );
};

type ModalProps = ModalContentProps & {
  isOpen: boolean;
  children?: React.ReactNode;
};

export const Modal: React.FC<ModalProps> = ({
  isOpen = false,
  children,
  ...props
}) => {
  return (
    <ModalRoot>
      <AnimatePresence>
        {isOpen && <ModalContent {...props}>{children}</ModalContent>}
      </AnimatePresence>
    </ModalRoot>
  );
};

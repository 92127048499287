import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { IDiningOptionEnum } from "@api/interfaces/configurationLayouts";
import { isPresent } from "@apl-digital/utils";
import Expandable from "@base/components/Global/Expandable";
import IconWithText from "@base/components/Global/IconWithText";
import { Modal } from "@base/components/Global/Modal";
import { RichListTranslation } from "@base/components/Global/RichTextTranslation";
import useSalesPointTranslationKey from "@base/hooks/useSalesPointTranslationKey";
import { NavigationPath } from "@constants/navigation";
import LocationModalContent from "@pages/HomePage/components/LocationModalContent";
import PreorderTimeSelectionWrapper from "@pages/HomePage/components/PreorderTimeSelectionWrapper";
import { useAppSelector } from "@store";
import { hasData } from "@store/apiRequestStatusMachine";
import {
  formatDeliveryDateAndTime,
  selectIsPreOrder,
  selectIsShoppingCartPreOrderAvailable,
  selectShoppingCart,
  selectShoppingCartPreOrderDeliveryData,
} from "@store/shoppingCart";
import { selectTheatreResource } from "@store/theatreResource";
import { getBlobStorage, getBrandTheme } from "@theme";

export const ResourceInfoBlock = () => {
  const { t } = useTranslation(["salespoint", "cartPage"]);
  const { resolveKey } = useSalesPointTranslationKey();

  const [isContactModalOpen, setIsContactModalOpen] = useState(false);

  const shoppingCart = useAppSelector(selectShoppingCart);
  const isPreOrder = useAppSelector(selectIsPreOrder);
  const tableInfo = useAppSelector(selectTheatreResource);
  const isPreorderAvailable = useAppSelector(
    selectIsShoppingCartPreOrderAvailable,
  );
  const shoppingCartPreOrderDeliveryDate = useAppSelector(
    selectShoppingCartPreOrderDeliveryData,
  );

  const [isPreorderWidgetExpanded, setIsPreorderWidgetExpanded] =
    useState(false);

  const { schedulingIcon, locationIcon } = getBlobStorage();
  const { spacing } = getBrandTheme();

  if (!hasData(shoppingCart)) {
    return null;
  }

  let title = t("cartPage:ResourceInfoBlock.method.default.title");

  const cartDiningOption = shoppingCart.state.Defaults?.DiningOptionID;

  if (hasData(tableInfo)) {
    if (cartDiningOption === IDiningOptionEnum.TAKE_OUT) {
      if (isPreOrder) {
        title = t("cartPage:ResourceInfoBlock.method.takeOut.preorder.title");
      } else {
        title = t("cartPage:ResourceInfoBlock.method.takeOut.order.title");
      }
    } else if (cartDiningOption === IDiningOptionEnum.DINE_IN) {
      if (isPreOrder) {
        title = t(
          "cartPage:ResourceInfoBlock.method.dineIn.withResource.preorder.title",
          {
            tableCode: tableInfo.state.Barcode,
          },
        );
      } else {
        title = t(
          "cartPage:ResourceInfoBlock.method.dineIn.withResource.order.title",
          {
            tableCode: tableInfo.state.Barcode,
          },
        );
      }
    }
  } else {
    if (isPreOrder) {
      title = t(
        "cartPage:ResourceInfoBlock.method.dineIn.withoutResource.preorder.title",
      );
    } else {
      title = t(
        "cartPage:ResourceInfoBlock.method.dineIn.withoutResource.order.title",
      );
    }
  }

  // for Google Analytics
  const isOrderStatusPage = window.location.pathname.includes(
    NavigationPath.OrderBase,
  );
  const isHomePage = window.location.pathname === NavigationPath.Home;
  const isCartPage = window.location.pathname === NavigationPath.Cart;
  const pageName =
    (isHomePage && "homepage") ||
    (isOrderStatusPage && "order-status-page") ||
    (isCartPage && "cart-page") ||
    "";

  return (
    <>
      <Modal
        isOpen={isContactModalOpen}
        onClose={() => setIsContactModalOpen(false)}
      >
        <LocationModalContent />
      </Modal>

      <Expandable
        isExpandable={isPreorderAvailable}
        title={title}
        isExpanded={isPreorderWidgetExpanded}
        setIsExpanded={setIsPreorderWidgetExpanded}
        renderHeaderPayload={() => (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: spacing.xs,
            }}
          >
            {isPresent(shoppingCartPreOrderDeliveryDate) && (
              <IconWithText iconUrl={schedulingIcon}>
                {formatDeliveryDateAndTime(
                  shoppingCartPreOrderDeliveryDate,
                  t("cartPage:ResourceInfoBlock.dateTime.relativeDate.today"),
                  t(
                    "cartPage:ResourceInfoBlock.dateTime.relativeDate.tomorrow",
                  ),
                )}
              </IconWithText>
            )}
            <IconWithText
              iconUrl={locationIcon}
              onClick={() => setIsContactModalOpen(true)}
              interactiveElementId={`${pageName}-contact-modal-open-btn`}
            >
              <RichListTranslation
                getTranslation={() =>
                  t(resolveKey("addressLines", { returnObjects: true }), {
                    returnObjects: true,
                  })
                }
              />
            </IconWithText>
          </div>
        )}
      >
        <div style={{ padding: `${spacing.m}px 0` }}>
          <PreorderTimeSelectionWrapper
            datesRefetchTrigger={isPreorderWidgetExpanded}
            onCartCreate={() => setIsPreorderWidgetExpanded(false)}
          />
        </div>
      </Expandable>
    </>
  );
};

import React, { useEffect, useRef, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { useAuth } from "react-oidc-context";
import { useNavigate } from "react-router-dom";
import { IDiningOptionEnum } from "@api/interfaces/configurationLayouts";
import { isPresent } from "@apl-digital/utils";
import Box from "@base/components/Box/Box";
import BoxList from "@base/components/Box/BoxList";
import Footer from "@base/components/Global/Footer";
import SignedInBlock from "@base/components/LoyaltyClub/SignedInBlock";
import SignInBlock from "@base/components/LoyaltyClub/SignInBlock";
import useWindowSize from "@base/hooks/useWindowSize";
import { NavigationPath } from "@constants/navigation";
import { useUser } from "@context/UserProvider";
import CartItemsBlock, {
  CartItemsBlockVariant,
} from "@pages/CartPage/components/CartItemsBlock";
import TotalBlock, {
  TotalBlockVariant,
} from "@pages/CartPage/components/TotalBlock";
import { useAppSelector } from "@store";
import { hasData } from "@store/apiRequestStatusMachine";
import { selectShouldShowPaymentErrorForCurrentCart } from "@store/payment/selectors";
import {
  selectShoppingCart,
  selectShoppingCartTotalItemCount,
} from "@store/shoppingCart";
import { isStatus } from "@store/utils";

import ContactDetailsForm from "../../forms/contactDetails/ContactDetailsForm";
import ContactDetailsFormProvider from "../../forms/contactDetails/ContactDetailsFormProvider";

import { CheckoutOptions } from "./components/CheckoutOptions";
import { ResourceInfoBlock } from "./components/ResourceInfoBlock";
import { VerifyAgeBlock } from "./components/VerifyAgeBlock";
import { useHighestMinRequiredAge } from "./hooks";

const useStyles = createUseStyles(({ color, spacing }) => ({
  blockContainer: {
    color: color.black,
    padding: spacing.xxl,
  },
  infoBlockContainer: {
    display: "flex",
    flexDirection: "column",
    gap: spacing.xl,
  },
  cartContainer: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
  },
  boxListOverride: {
    gap: spacing.x5l,
  },
  boxContainer: {
    gap: spacing.l,
  },
  contentContainer: {
    paddingTop: "48px",
  },
  takeOutContainerNote: {
    color: color.infoTextColor,
    margin: [spacing.xxl, spacing.x4l],
  },
  footerOverride: {
    gap: spacing.xxl,
  },
}));

const CartPage: React.FC = () => {
  const classes = useStyles();
  const shouldShowPaymentError = useAppSelector(
    selectShouldShowPaymentErrorForCurrentCart,
  );
  const shoppingCart = useAppSelector(selectShoppingCart);
  const [isAgeVerified, setIsAgeVerified] = useState(shouldShowPaymentError);
  const minRequiredAge = useHighestMinRequiredAge();
  const shoppingCartItemCount = useAppSelector(
    selectShoppingCartTotalItemCount,
  );
  const navigate = useNavigate();
  const { isMdAndUp } = useWindowSize();
  const { t } = useTranslation();
  const {
    formState: { isValid },
  } = useFormContext();
  const { shouldShowSignIn } = useUser();
  const { isAuthenticated } = useAuth();
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  const footerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isMdAndUp || !hasData(shoppingCart)) {
      navigate(NavigationPath.Home);
    }
  }, [isMdAndUp, shoppingCart]);

  useEffect(() => {
    if (shouldShowPaymentError) {
      window.scrollTo({
        behavior: "smooth",
        top: document.body.scrollHeight,
      });
    } else {
      window.scrollTo(0, 0);
    }
  }, [shouldShowPaymentError]);

  const handlePageShow = (e: PageTransitionEvent) => {
    if (e.persisted) {
      window.location.reload();
    }
  };

  useEffect(() => {
    window.addEventListener("pageshow", handlePageShow);

    return () => window.removeEventListener("pageshow", handlePageShow);
  }, []);

  if (!hasData(shoppingCart)) {
    return null;
  }

  const shouldVerifyAge = minRequiredAge > 0;

  const isCheckoutButtonDisabled =
    (shouldVerifyAge && !isAgeVerified) ||
    !isValid ||
    !shoppingCartItemCount ||
    !isStatus(shoppingCart, "succeeded");

  const footerHeight = isPresent(footerRef.current?.clientHeight)
    ? `${footerRef.current.clientHeight + 40}px`
    : undefined;

  return (
    <>
      <div
        className={classes.contentContainer}
        style={{ paddingBottom: `${footerHeight}` }}
      >
        <BoxList hasFooter={false} className={classes.boxListOverride}>
          <div className={classes.infoBlockContainer}>
            {shouldShowSignIn && (
              <div className={classes.blockContainer} id="user-login-bar">
                <SignInBlock />
              </div>
            )}

            {isAuthenticated && <SignedInBlock variant="filled" />}

            <Box>
              <ResourceInfoBlock />
            </Box>
          </div>

          <div>
            <CartItemsBlock variant={CartItemsBlockVariant.PAGE} />

            {shoppingCart.state.Defaults?.DiningOptionID ===
              IDiningOptionEnum.TAKE_OUT && (
              <div className={classes.takeOutContainerNote}>
                {t("orders_takeaway_extra_info")}
              </div>
            )}
            <TotalBlock
              total={shoppingCart.state.Sums}
              variant={TotalBlockVariant.PAGE}
            />
          </div>
          <div ref={scrollContainerRef}>
            <Box
              classNames={classes.boxContainer}
              id="contact-details-container"
            >
              <ContactDetailsForm />
            </Box>
          </div>

          {shouldVerifyAge && (
            <VerifyAgeBlock
              isChecked={isAgeVerified}
              onClick={() => setIsAgeVerified((prevState) => !prevState)}
            />
          )}
        </BoxList>
      </div>
      <Footer
        ref={footerRef}
        isError={shouldShowPaymentError}
        hasExtraHeight={shouldShowPaymentError}
        override={classes.footerOverride}
      >
        <CheckoutOptions
          isDisabled={isCheckoutButtonDisabled}
          totalSum={0}
          variant="mobile"
          scrollContainerRef={scrollContainerRef}
        />
      </Footer>
    </>
  );
};

const CartPageWithContactForm = () => {
  return (
    <ContactDetailsFormProvider>
      <CartPage />
    </ContactDetailsFormProvider>
  );
};

export default CartPageWithContactForm;

import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { isDefined } from "@apl-digital/utils";
import Icon from "@base/components/Global/Icon";
import useInterval from "@base/hooks/useInterval";
import useSalesPointTranslationKey from "@base/hooks/useSalesPointTranslationKey";
import useWindowSize from "@base/hooks/useWindowSize";
import config from "@constants/config";
import { NavigationPath } from "@constants/navigation";
import { getBlobStorage, getBrandTheme } from "@theme";

import GenericModal from "../Global/GenericModal";

import { BurgerMenuUser } from "./BurgerMenuUser/BurgerMenuUser";
import { MenuItem } from "./BurgerMenuItem";
import { BurgerMenuLanguage } from "./BurgerMenuLanugage";
import { BurgerMenuMyOrder } from "./BurgerMenuMyOrder";

const containerWithoutMargins = (spacing: number) => 1464 - spacing * 2;

const useStyles = createUseStyles(({ spacing, color, zIndex }) => ({
  burgerMenuContainer: {
    background: color.burgerMenuBg,
    color: color.burgerMenuText,
    zIndex: zIndex.burgerMenuOverlay,
    width: "100%",
  },
  burgerMenuContainerDesktop: {
    background: color.burgerMenuBg,
    color: color.burgerMenuText,
    zIndex: zIndex.burgerMenuOverlay,
    width: 450,
    borderBottomLeftRadius: 24,
    borderBottomRightRadius: 24,
    height: "fit-content",
    maxHeight: "100%",
    marginRight: `max(calc((100% - ${containerWithoutMargins(
      spacing.x5l,
    )}px) / 2), 0px)`,
  },
  menuContainer: {
    minHeight: "100%",
    position: "relative",
    zIndex: zIndex.burgerMenu,
    display: "flex",
    flexDirection: "column",
  },
  markusLogo: {
    display: "flex",
    width: "100%",
    marginTop: "auto",
    paddingBottom: spacing.xl,
    justifyContent: "center",
  },
  closeButton: {
    marginRight: spacing.xxl,
    cursor: "pointer",
  },
  menuItemTop: {
    height: "54px",
    textAlign: "right",
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "flex-end",
    borderBottom: `1px solid ${color.burgerMenuBorders}`,
    backgroundColor: color.burgerMenuItemTop,
  },
}));

type BurgerMenuProps = {
  hasSidebar: boolean;
  showSidebar: () => void;
};

type BurgerMenuLink = {
  label: string;
  url: string;
  iconKey?: string;
  newWindow?: string;
};

type BurgerMenuLinksObject = {
  [key: string]:
    | BurgerMenuLink
    | {
        [key: string]: BurgerMenuLink;
      };
};

const BurgerMenu: React.FC<BurgerMenuProps> = ({ hasSidebar, showSidebar }) => {
  const { isMdAndUp } = useWindowSize();
  const classes = useStyles();
  const { resolveKey } = useSalesPointTranslationKey();
  const [isClosed, setIsClosed] = useState<boolean>(false);
  const { t } = useTranslation(["salespoint", "translations"]);
  const allLinks = t("translations:burgerMenu", {
    returnObjects: true,
  }) as BurgerMenuLinksObject;
  const tenantSpecificLinks = allLinks[config.tenantConfig.key];
  const locationSpecificLinks =
    allLinks[config.tenantConfig.key + config.tenantConfig.location];

  const links = useMemo(
    () =>
      Object.entries({
        ...allLinks,
        ...tenantSpecificLinks,
        ...locationSpecificLinks,
      })
        .filter(
          ([key, link]) =>
            key.startsWith("link") &&
            isDefined(link?.label) &&
            isDefined(link?.url),
        )
        .sort(([key1], [key2]) => {
          const index1 = Number(key1.split("-")[1]);
          const index2 = Number(key2.split("-")[1]);
          if (isNaN(index1) || isNaN(index2)) return 0;
          return index1 - index2;
        })
        .map(([, link]) => ({
          label: link.label,
          url: link.url,
          iconKey: Number.isNaN(link.iconKey) ? null : Number(link.iconKey) - 1,
          newWindow: String(link.newWindow).toLocaleLowerCase() === "true",
        })),
    [allLinks, locationSpecificLinks, tenantSpecificLinks],
  );

  useInterval(() => setIsClosed(false), 500, isClosed);

  const handleClose = (): void => {
    setIsClosed(true);
    showSidebar();
  };

  const { markusLogoBlack, closeIcon, locationIcon, burgerMenuLinks } =
    getBlobStorage();
  const { color, zIndex } = getBrandTheme();

  return (
    <GenericModal
      isOpen={hasSidebar}
      closeModal={showSidebar}
      transitionVariant="rightToLeft"
      override={
        isMdAndUp
          ? classes.burgerMenuContainerDesktop
          : classes.burgerMenuContainer
      }
      customZIndex={zIndex.burgerMenuOverlay}
      isMdAndUp
      shouldHideRootScrollbar={false}
      allowCloseOnEscape
    >
      <div className={classes.menuContainer}>
        <div className={classes.menuItemTop}>
          <div
            className={classes.closeButton}
            id="burger-menu-close-btn"
            onClick={handleClose}
          >
            <Icon
              height={32}
              width={32}
              url={closeIcon}
              stroke={color.burgerMenuIcons}
            />
          </div>
        </div>
        <div>
          <BurgerMenuUser onClick={handleClose} />

          <BurgerMenuMyOrder closeBurgerMenu={handleClose} />

          <MenuItem
            label={t(resolveKey("name"))}
            icon={locationIcon}
            path={NavigationPath.Location}
            interactiveElementId="burger-menu-contact-modal-open-btn"
            onClick={handleClose}
          />

          {links.map((link) => (
            <MenuItem
              key={link.label + link.iconKey}
              label={link.label}
              icon={
                link.iconKey !== null
                  ? burgerMenuLinks[link.iconKey]
                  : undefined
              }
              path={link.url}
              newWindow={link.newWindow}
              onClick={handleClose}
            />
          ))}

          <BurgerMenuLanguage />
        </div>

        <div className={classes.markusLogo}>
          <Icon height={28} width={139} url={markusLogoBlack} />
        </div>
      </div>
    </GenericModal>
  );
};

export default BurgerMenu;

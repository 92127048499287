import React, { createContext, useContext, useEffect } from "react";
import { ISalesPointConfiguration } from "@api/interfaces/configurationLayouts";
import Loader from "@base/components/Global/Loader";
import { isInIframe } from "@base/utils/iframeUtils";
import { ErrorPage } from "@pages/ErrorPage";
import { useAppDispatch, useAppSelector } from "@store";
import { shouldLoad } from "@store/apiRequestStatusMachine";
import { loadSalesPointConfiguration } from "@store/salesPoint";
import {
  getTheatreResource,
  selectTheatreResource,
} from "@store/theatreResource";
import { isStatus } from "@store/utils";

import { getAvailableDates, getAvailableTimes } from "./actions";
import {
  SalesPointActions,
  SalesPointContextProps,
  SalesPointState,
} from "./types";

const DEFAULT_STATE: SalesPointState = {
  configuration: {} as ISalesPointConfiguration,
};

const DEFAULT_ACTIONS: SalesPointActions = {
  getAvailableDates: async () => undefined,
  getAvailableTimes: async () => undefined,
};

const SalesPointContext = createContext<SalesPointContextProps>([
  DEFAULT_STATE,
  DEFAULT_ACTIONS,
]);

export const useSalesPointContext = () => useContext(SalesPointContext);

type SalesPointContextProviderProps = {
  children?: React.ReactNode;
};

export const SalesPointContextProvider: React.FC<
  SalesPointContextProviderProps
> = ({ children }) => {
  const dispatch = useAppDispatch();
  const configuration = useAppSelector(
    (state) => state.salesPoint.configuration,
  );
  const tableInfo = useAppSelector(selectTheatreResource);

  useEffect(() => {
    if (isInIframe()) {
      return;
    }

    if (shouldLoad(configuration)) {
      dispatch(loadSalesPointConfiguration());
    }

    if (isStatus(tableInfo, "stale")) {
      dispatch(
        getTheatreResource({ barcodeWithoutChecksum: tableInfo.state.Barcode }),
      );
    }
  }, [configuration]);

  if (!isInIframe() && isStatus(configuration, ["pending", "idle"])) {
    return <Loader isFullScreen />;
  }

  if (!isInIframe() && isStatus(configuration, ["failed", "failedPending"])) {
    return (
      <ErrorPage
        error={{
          type: "admin",
          messageForAdmin: () => {
            console.error(
              `Failed to load sales point configuration, got response code ${configuration.state?.responseStatus} from API`,
            );
            console.error(configuration.state?.reason);
          },
        }}
      />
    );
  }

  return (
    <SalesPointContext.Provider
      value={[
        {
          configuration: configuration.state as ISalesPointConfiguration,
        },
        {
          getAvailableDates,
          getAvailableTimes,
        },
      ]}
    >
      {children}
    </SalesPointContext.Provider>
  );
};

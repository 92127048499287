import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { useAuth } from "react-oidc-context";
import { isPresent } from "@apl-digital/utils";
import Icon from "@base/components/Global/Icon";
import { useUser } from "@context/UserProvider";
import { useAppSelector } from "@store/hooks";
import { selectUserData } from "@store/user";
import { getBlobStorage } from "@theme";

type StyleProps = {
  variant: "ghost" | "filled";
};

const useStyles = createUseStyles(({ spacing, font, color, borderRadius }) => ({
  userInfo: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: spacing.xl,
    backgroundColor: ({ variant }: StyleProps) =>
      variant === "filled" && color.signedInBlockBg,
    border: ({ variant }: StyleProps) =>
      variant === "filled" && `1px solid ${color.signedInBlockBorder}`,
    borderRadius: borderRadius.button,
  },
  userInfoAndCard: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    flex: 2,
  },
  userNameLastName: {
    flex: 3,
    paddingLeft: spacing.s,
    "& h4": {
      margin: 0,
      color: color.signedInBlockH4,
      fontWeight: font.weight.m,
      fontSize: font.size.m,
    },
    "& h5": {
      margin: 0,
      color: color.signedInBlockH5,
      fontWeight: font.weight.m,
      fontSize: font.size.xxs,
      lineHeight: font.lineHeight.x3s,
    },
  },
  userSwitchAccount: {
    display: "flex",
    flexDirection: "column",
    alignItems: "end",
    "& p": {
      margin: 0,
      color: color.signedInBlockSwitchAccount,
      fontSize: font.size.x3s,
      lineHeight: font.lineHeight.x4s,
    },
    "& a": {
      color: color.signedInBlockSwitchBtn,
      fontSize: font.size.s,
      lineHeight: font.lineHeight.xxs,
    },
  },
  loginButtonContainer: {
    display: "flex",
    flexDirection: "row",
    gap: spacing.m,
    padding: [spacing.l, spacing.xxl],
    width: "100%",
  },
  outlineButton: {
    border: `1px solid ${color.burgerMenuOutlineBtnBorder}`,
    background: color.burgerMenuLoginButtonBg,
    color: color.burgerMenuLoginButtonText,
    gap: spacing.xs,
    width: "50%",
  },
  loginButton: {
    background: color.burgerMenuBtnBg,
    color: color.burgerMenuBtnText,
    height: "auto",
  },
}));

type SignedInBlockProps = StyleProps;

const SignedInBlock: React.FC<SignedInBlockProps> = ({
  variant = "filled",
}) => {
  const classes = useStyles({ variant });
  const { t } = useTranslation();
  const { logout } = useUser();
  const userData = useAppSelector(selectUserData);
  const { user } = useAuth();

  const { tinyLoyaltyClubCardIcon } = getBlobStorage();

  const name = useMemo(() => {
    if (isPresent(user?.profile.name)) {
      return user?.profile.name;
    }

    if (isPresent(userData?.FirstName) && isPresent(userData?.LastName)) {
      return `${userData?.FirstName} ${userData?.LastName}`;
    }

    return "";
  }, [userData, user]);

  return (
    <>
      <div className={classes.userInfo}>
        <div className={classes.userInfoAndCard}>
          <Icon url={tinyLoyaltyClubCardIcon} width={62} height={40} />
          <div className={classes.userNameLastName}>
            <h4 data-sentry-mask={true}>{name}</h4>
            <h5>{t("burgermenu_main_user_status")}</h5>
          </div>
        </div>
        <div className={classes.userSwitchAccount} onClick={logout}>
          <p>{t("burgermenu_main_not_you")}</p>
          <a href="#">{t("burgermenu_main_switch_account")}</a>
        </div>
      </div>
    </>
  );
};

export default SignedInBlock;

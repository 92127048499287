import { CartStatus, ICartLayout } from "@api/interfaces/cartLayouts";
import { isPresent } from "@apl-digital/utils";
import { getStorage, StorageKey } from "@constants/storage";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  hasData,
  setStateFailed,
  setStatePending,
} from "@store/apiRequestStatusMachine";

import {
  addProductToCart,
  bundleShoppingCart,
  initializeDiningCart,
  refreshCart,
  removeCustomerFromCart,
  removeLinesFromCart,
  unbundleShoppingCart,
  updateCustomerCart,
} from "./actions";
import { ShoppingCartState } from "./types";

const getInitialState = (loadFromStorage: boolean): ShoppingCartState => {
  const storedCart = getStorage(StorageKey.CART);

  return {
    shoppingCart: loadFromStorage
      ? isPresent(storedCart)
        ? { status: "stale", state: JSON.parse(storedCart) }
        : { status: "idle" }
      : { status: "idle" },
    shoppingCartLanguage: loadFromStorage
      ? getStorage(StorageKey.SHOPPING_CART_LANGUAGE)
      : null,
  };
};

export const shoppingCartSlice = createSlice({
  name: "shoppingCart",
  initialState: getInitialState(true),
  reducers: {
    resetShoppingCartSlice: () => {
      return getInitialState(false);
    },
    resetShoppingCart: (state) => {
      state.shoppingCart = getInitialState(false).shoppingCart;
    },
    shoppingCartLanguageChanged: (
      state,
      { payload }: PayloadAction<string>,
    ) => {
      state.shoppingCartLanguage = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(removeCustomerFromCart.pending, (state) => {
        setStatePending<ICartLayout>(
          state.shoppingCart,
          (nextState) => (state.shoppingCart = nextState),
        );
      })
      .addCase(removeCustomerFromCart.fulfilled, (state, { payload }) => {
        state.shoppingCart = { status: "succeeded", state: payload };
      })
      .addCase(removeCustomerFromCart.rejected, (state) => {
        setStateFailed<ICartLayout>(
          state.shoppingCart,
          (nextState) => (state.shoppingCart = nextState),
        );
      });

    builder
      .addCase(updateCustomerCart.pending, (state) => {
        setStatePending<ICartLayout>(
          state.shoppingCart,
          (nextState) => (state.shoppingCart = nextState),
        );
      })
      .addCase(updateCustomerCart.fulfilled, (state, { payload }) => {
        state.shoppingCart = { status: "succeeded", state: payload };
      })
      .addCase(updateCustomerCart.rejected, (state) => {
        setStateFailed<ICartLayout>(
          state.shoppingCart,
          (nextState) => (state.shoppingCart = nextState),
        );
      });

    builder
      .addCase(initializeDiningCart.pending, (state) => {
        setStatePending<ICartLayout>(
          state.shoppingCart,
          (nextState) => (state.shoppingCart = nextState),
        );
      })
      .addCase(initializeDiningCart.fulfilled, (state, { payload }) => {
        state.shoppingCart = { status: "succeeded", state: payload };
      })
      .addCase(initializeDiningCart.rejected, (state) => {
        setStateFailed<ICartLayout>(
          state.shoppingCart,
          (nextState) => (state.shoppingCart = nextState),
        );
      });

    builder
      .addCase(bundleShoppingCart.pending, (state) => {
        setStatePending<ICartLayout>(
          state.shoppingCart,
          (nextState) => (state.shoppingCart = nextState),
        );
      })
      .addCase(bundleShoppingCart.fulfilled, (state, { payload }) => {
        state.shoppingCart = { status: "succeeded", state: payload };
      })
      .addCase(bundleShoppingCart.rejected, (state) => {
        setStateFailed<ICartLayout>(
          state.shoppingCart,
          (nextState) => (state.shoppingCart = nextState),
        );
      });

    builder
      .addCase(unbundleShoppingCart.pending, (state) => {
        setStatePending<ICartLayout>(
          state.shoppingCart,
          (nextState) => (state.shoppingCart = nextState),
        );
      })
      .addCase(unbundleShoppingCart.fulfilled, (state, { payload }) => {
        state.shoppingCart = { status: "succeeded", state: payload };
      })
      .addCase(unbundleShoppingCart.rejected, (state) => {
        setStateFailed<ICartLayout>(
          state.shoppingCart,
          (nextState) => (state.shoppingCart = nextState),
        );
      });

    builder
      .addCase(refreshCart.pending, (state) => {
        setStatePending<ICartLayout>(
          state.shoppingCart,
          (nextState) => (state.shoppingCart = nextState),
        );
      })
      .addCase(refreshCart.fulfilled, (state, { payload }) => {
        state.shoppingCart = { status: "succeeded", state: payload };
      })
      .addCase(refreshCart.rejected, (state) => {
        setStateFailed<ICartLayout>(
          state.shoppingCart,
          (nextState) => (state.shoppingCart = nextState),
        );
      });

    builder.addCase(addProductToCart.pending, (state) => {
      setStatePending<ICartLayout>(
        state.shoppingCart,
        (nextState) => (state.shoppingCart = nextState),
      );
    });

    builder.addCase(removeLinesFromCart.pending, (state) => {
      setStatePending<ICartLayout>(
        state.shoppingCart,
        (nextState) => (state.shoppingCart = nextState),
      );
    });
  },
  selectors: {
    selectShoppingCart: (state) => state.shoppingCart,
    selectCurrentBarcodeWithoutChecksum: (state): string | undefined =>
      hasData(state.shoppingCart)
        ? state.shoppingCart.state.BarcodeWithoutChecksum
        : undefined,
    selectCurrentTransactionId: (state): number | undefined =>
      hasData(state.shoppingCart) ? state.shoppingCart.state.ID : undefined,
    selectCanEditShoppingCart: (state): boolean =>
      hasData(state.shoppingCart) &&
      state.shoppingCart.state.Status !== CartStatus.ON_HOLD,
    selectCheckoutOptions: (state) =>
      hasData(state.shoppingCart)
        ? state.shoppingCart.state.CheckoutOptions
        : [],
    selectShoppingCartLines: (state) =>
      hasData(state.shoppingCart) && state.shoppingCart.state.Lines
        ? state.shoppingCart.state.Lines
        : undefined,
    selectShoppingCartSums: (state) =>
      hasData(state.shoppingCart) ? state.shoppingCart.state.Sums : undefined,
    selectShoppingCartContactInfo: (state) =>
      hasData(state.shoppingCart)
        ? state.shoppingCart.state.ContactInfo
        : undefined,
    selectIsPreOrder: (state) =>
      hasData(state.shoppingCart) &&
      state.shoppingCart.state.Defaults?.dttmRequestedDelivery !== null,
  },
});

export const {
  resetShoppingCartSlice,
  resetShoppingCart,
  shoppingCartLanguageChanged,
} = shoppingCartSlice.actions;

export const {
  selectShoppingCart,
  selectCurrentBarcodeWithoutChecksum,
  selectCurrentTransactionId,
  selectCanEditShoppingCart,
  selectCheckoutOptions,
  selectShoppingCartLines,
  selectShoppingCartSums,
  selectShoppingCartContactInfo,
  selectIsPreOrder,
} = shoppingCartSlice.selectors;

export default shoppingCartSlice.reducer;
